import { createApp } from 'vue';
import SearchForm from './ui/components/3-organisms/HeaderNavigation/SearchForm.vue';

const allSearchForms = document.getElementsByClassName('m-search-form');
for (let i = 0; i < allSearchForms.length; i++) {
  let mountElementDataset = allSearchForms[i].dataset;
  const app = createApp(SearchForm, {
    config: mountElementDataset,
    component_id: i,
  });
  app.mount(allSearchForms[i]);
}
